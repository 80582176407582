<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body">
            <b-form class="form">
              <fieldset ref="general_information">
                <v-legend
                  :title="'General Information'"
                  :description="'Fill in the employee\'s personal data'"
                ></v-legend>
                <div class="row">
                  <div class="col-lg-3">
                    <b-form-group label="">
                      <label for=""
                        >Employee ID <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        class="form-control"
                        :class="
                          errors
                            ? errors['employee_id']
                              ? 'is-invalid'
                              : ''
                            : ''
                        "
                        v-model="formdata.employee_id"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["employee_id"]
                              ? errors["employee_id"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-lg-3">
                    <b-form-group label="">
                      <label for=""
                        >First Name <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        class="form-control"
                        :class="
                          errors
                            ? errors['first_name']
                              ? 'is-invalid'
                              : ''
                            : ''
                        "
                        v-model="formdata.first_name"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["first_name"]
                              ? errors["first_name"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="col-lg-3">
                    <b-form-group label="">
                      <label for="">Middle Name</label>
                      <b-form-input
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.middle_name"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-3">
                    <b-form-group label="">
                      <label for=""
                        >Last Name <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        class="form-control"
                        :class="
                          errors
                            ? errors['last_name']
                              ? 'is-invalid'
                              : ''
                            : ''
                        "
                        v-model="formdata.last_name"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["last_name"]
                              ? errors["last_name"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Gender <span class="text-danger">*</span></label
                      >
                      <select
                        class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                        :class="
                          errors ? (errors['gender'] ? 'is-invalid' : '') : ''
                        "
                        v-model="formdata.gender"
                      >
                        <option value="">--choose--</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["gender"]
                              ? errors["gender"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Date of birth <span class="text-danger">*</span></label
                      >
                      <input
                        type="date"
                        class="form-control"
                        :class="
                          errors ? (errors['dob'] ? 'is-invalid' : '') : ''
                        "
                        v-model="formdata.dob"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors ? (errors["dob"] ? errors["dob"][0] : "") : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Email Address <span class="text-danger">*</span></label
                      >
                      <input
                        type="email"
                        class="form-control"
                        :class="
                          errors ? (errors['email'] ? 'is-invalid' : '') : ''
                        "
                        v-model="formdata.email"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["email"]
                              ? errors["email"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Contact number
                        <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        :class="
                          errors
                            ? errors['contact_number']
                              ? 'is-invalid'
                              : ''
                            : ''
                        "
                        v-model="formdata.contact_number"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["contact_number"]
                              ? errors["contact_number"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for="">PAN Number</label>
                      <input
                        :class="
                          errors ? (errors['pan_no'] ? 'is-invalid' : '') : ''
                        "
                        type="number"
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.pan_no"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["pan_no"]
                              ? errors["pan_no"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Joined Date <span class="text-danger">*</span></label
                      >
                      <input
                        type="date"
                        class="form-control"
                        :class="
                          errors
                            ? errors['joining_date']
                              ? 'is-invalid'
                              : ''
                            : ''
                        "
                        v-model="formdata.joining_date"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["joining_date"]
                              ? errors["joining_date"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
              </fieldset>

              <fieldset ref="address">
                <v-legend
                  :title="'Address'"
                  :description="'Fill in the employee\'s address'"
                ></v-legend>

                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >City <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        class="form-control"
                        :class="
                          errors ? (errors['city'] ? 'is-invalid' : '') : ''
                        "
                        v-model="formdata.city"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["city"]
                              ? errors["city"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for="">Zip Code</label>
                      <b-form-input
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.zip_code"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >State / Province / Region
                        <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        :class="
                          errors ? (errors['province'] ? 'is-invalid' : '') : ''
                        "
                        class="form-control"
                        v-model="formdata.province"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["province"]
                              ? errors["province"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <b-form-group label="">
                      <label for=""
                        >Address Line 1
                        <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        :class="
                          errors
                            ? errors['address_line_1']
                              ? 'is-invalid'
                              : ''
                            : ''
                        "
                        class="form-control"
                        v-model="formdata.address_line_1"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{
                          errors
                            ? errors["address_line_1"]
                              ? errors["address_line_1"][0]
                              : ""
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div class="col-lg-6">
                    <b-form-group label="">
                      <label for="">Address Line 2 </label>
                      <b-form-input
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.address_line_2"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </fieldset>

              <fieldset ref="emergency_contact">
                <v-legend
                  :title="'Emergency Contact'"
                  :description="
                    'Personal info about the employee\'s emergency contact'
                  "
                ></v-legend>

                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Name </label
                      >
                      <b-form-input
                        class="form-control"
                        v-model="formdata.emergency_contact_name"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for=""
                        >Phone Number </label
                      >
                      <b-form-input
                       
                        class="form-control"
                        v-model="formdata.emergency_contact_number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for="">Relationship With</label>
                      <b-form-input
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.relationship"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </fieldset>

              <fieldset ref="bank_information">
                <v-legend
                  :title="'Bank Information'"
                  :description="'Bank Detail of an employee'"
                ></v-legend>

                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for="">Name of Bank</label>
                      <b-form-input
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.bank_name"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for="">Account Full Name</label>
                      <b-form-input
                        class="form-control"
                        v-model="formdata.account_name"
                        placeholder="optional"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4">
                    <b-form-group label="">
                      <label for="">Account Number</label>
                      <b-form-input
                        placeholder="optional"
                        class="form-control"
                        v-model="formdata.account_number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </fieldset>
            </b-form>
          </div>
          <div class="card-footer">
            <button
              type="button"
              @click="onSubmit()"
              ref="kt_add_new_employee"
              class="btn mr-3 btn-success float-right"
            >
              Next
              <i class="fa fa-arrow-right ml-2"></i>
            </button>
            <button type="button" class="btn mr-3 btn-danger float-right">
              <i class="fa fa-times"></i>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { ADD_NEW_EMPLOYEE } from "@/core/services/store/actions.type";
import Legend from "@/includes/content/widgets/Legend";
// import { mapGetters } from "vuex";
export default {
  data() {
    return {
      errors: [],
      formdata: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        employee_id: "",
        gender: "",
        dob: "",
        contact_number: "",
        province: "",
        city: "",
        zip_code: "",
        address_line_1: "",
        address_line_2: "",
        pan_no: 0,
        emergency_contact_name: "",
        emergency_contact_number: "",
        relationship: "",
        bank_name: "",
        account_name: "",
        account_number: "",
        joining_date: ""
      }
    };
  },
  components: {
    "v-legend": Legend
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Employees List", route: "/employees/list" },
      { title: "New employee" }
    ]);
  },

  methods: {
    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append
      });
    },
    onSubmit() {
      const formdata = this.formdata;

      // s spinner to submit button
      const submitButton = this.$refs["kt_add_new_employee"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(ADD_NEW_EMPLOYEE, formdata)
        // go to which page after successfully login
        .then(response => {

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("success", "Success!", true, response.message);
          this.errors = [];
          this.$router.push({
            name: "employee.edit.document",
            params: { id: response.data.id }
          });
        })
        .catch(errors => {
          this.errors = errors.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("danger", "Error!", true, errors.message);
        });
    }
  }
};
</script>
